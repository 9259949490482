document.addEventListener('DOMContentLoaded', function () {

    $('.toggle__item .toggle__item-title').on('click', function () {
        $('.toggle__item .toggle__item-descr').not($(this).next()).slideUp(300);
        $(this).next().slideToggle(300);
        $(this).toggleClass('toggle__item-title-active');
        $(this).parents('.toggle__item').siblings().find('div').removeClass('toggle__item-title-active');
    });


    $(".scroll-to").on("click", function (event) {
        event.preventDefault();
        var id = $(this).attr('href'),
            top = $(id).offset().top - 100;
        $('body,html').animate({ scrollTop: top }, 500);
    });

    $('.burger').on('click', function () {
        $('.burger, .nav ').toggleClass('active');
        $('body').toggleClass('scroll-hide');
    });

    $('.nav__link').click(function () {
        $('.burger, .nav ').removeClass('active');
        $('body').removeClass('scroll-hide');
    });


    const btnTop = document.querySelector('.header');

    window.addEventListener('scroll', function () {
        let scrollTop = window.scrollY;

        if (scrollTop > 1) {
            btnTop.classList.add('active');
        } else {
            btnTop.classList.remove('active');

        }
    });



    const instanceA = basicScroll.create({
        elem: document.querySelector('.about__img'),
        from: 'top-bottom',
        to: 'top-top',
        props: {
            '--position': {
                from: '100px',
                to: '0'
            },
        }
    });

    document.querySelectorAll('.form-sec__img').forEach(item => {
        basicScroll.create({
            elem: item,
            from: 'top-bottom',
            to: 'top-top',
            direct: true,
            props: {
                '--positionX': {
                    from: '100px',
                    to: '0'
                },
            }
        }).start();
    });



    const instanceC = basicScroll.create({
        elem: document.querySelector('.nutrition-decor'),
        from: 'top-bottom',
        to: 'top-top',
        props: {
            '--scale': {
                from: '0.2',
                to: '1'
            },
            '--rotate': {
                from: '60deg',
                to: '0'
            },
        }
    });
    const instanceC2 = basicScroll.create({
        elem: document.querySelector('.decor3'),
        from: 'top-bottom',
        to: 'top-top',
        props: {
            '--scale2': {
                from: '1',
                to: '0.5'
            },
            '--rotate2': {
                from: '0',
                to: '60deg'
            },
        }
    });

    const instanceD = basicScroll.create({
        elem: document.querySelector('.question__img'),
        from: 'top-bottom',
        to: 'top-top',
        props: {
            '--scale3': {
                from: '0.4',
                to: '1'
            },
            '--rotate3': {
                from: '60deg',
                to: '0'
            },
        }
    });

    instanceA.start();

    instanceC.start();
    instanceC2.start();
    instanceD.start();

});