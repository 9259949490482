const header = document.querySelector('.header'),
    modaWrap = document.querySelectorAll('.modal-wrap'),
    scrollHide = calcScroll();


function bindModal(triggerSelector, modalSelector) {
    const trigger = document.querySelectorAll(triggerSelector),
        modal = document.querySelector(modalSelector);

    trigger.forEach(item => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            closeModal();
            modal.classList.add('active');
            hideScroll();
        });
    });
}

function calcScroll() {
    let div = document.createElement('div');

    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    return scrollWidth;
}

function hideScroll() {
    document.body.style.overflow = "hidden";
    /* header.style.width = `calc(100% - ${scrollHide}px)`; */
    document.body.style.marginRight = `${scrollHide}px`;
    modaWrap.forEach(item => {
        item.style.width = `calc(100% - ${scrollHide}px)`;
    });
}

function showScroll() {
    document.body.style.overflow = "";
    document.body.style.marginRight = '';
    header.style.width = '';
    modaWrap.forEach(item => {
        item.style.width = '';
    });
}

function showModal(modalItem) {
    const modal = document.querySelector(modalItem);
    modal.classList.add('active');
    hideScroll();
}


bindModal('.promo__btn', '.modal-aplication');

//showModal('.modal-thanks');


//////////////////////////////////////////////////////////////////////////////

function closeModal() {
    const modalAll = document.querySelectorAll('.modal-wrap');

    modalAll.forEach(item => {
        item.classList.remove('active');
        showScroll();
    });

}

function closeAllModal() {
    const modalAll = document.querySelectorAll('.modal-wrap');
    const modalClose = document.querySelectorAll('.modal__close');

    modalClose.forEach(item => {
        item.addEventListener('click', function () {
            closeModal();
        });
    });

    modalAll.forEach(item => {
        item.addEventListener('click', function (e) {
            if (e.target === item) {
                item.classList.remove('active');
                showScroll();
            }
        });
    });
}

closeAllModal();


/////////////////////////////////////////////////////////////////////////


function modalService() {
    const modalTrigger = document.querySelectorAll('.service-page-item');
    const modalClose = document.querySelectorAll('.modal-service__close');
    const modalAll = document.querySelectorAll('.modal-wrap-service');

    modalTrigger.forEach(item => {
        item.addEventListener("click", function () {
            let currentBtn = item;
            let tabId = currentBtn.getAttribute("data-modal");
            let currentModal = document.querySelector(tabId);

            currentModal.classList.add('active');
            hideScroll();
        });
    });

    function closeModal() {
        modalAll.forEach(item => {
            item.classList.remove('active');
        });
        showScroll();
    }

    modalAll.forEach(item => {
        item.addEventListener('click', function (e) {
            if (e.target === item) {
                closeModal();
            }
        });
    });

    modalClose.forEach(item => {
        item.addEventListener('click', function () {
            closeModal();
        });
    });

}

if (document.querySelector('.service-page-item')) {
    modalService();
}






