document.addEventListener('DOMContentLoaded', function () {

    const everydayLifeSwiper = new Swiper(".everyday-life-swiper", {
        slidesPerView: 3,
        spaceBetween: 20,
        loop: true,
        speed: 1000,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            780: {
                slidesPerView: 3,
            },

            480: {
                slidesPerView: 2,
            },
            300: {
                slidesPerView: 1,
            },
        },
    });

    const ourTeamSwiper = new Swiper(".our-team-swiper", {
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        speed: 1000,
        navigation: {
            nextEl: ".our-team .swiper-button-next",
            prevEl: ".our-team .swiper-button-prev",
        },
        breakpoints: {
            1260: {
                slidesPerView: 4,
            },
            780: {
                slidesPerView: 3,
            },
            580: {
                slidesPerView: 2,
            },
            300: {
                slidesPerView: 1,
            },
        },
    });

    const reviewsSwiper = new Swiper(".reviews-swiper", {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: true,
        speed: 1000,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".reviews .swiper-button-next",
            prevEl: ".reviews .swiper-button-prev",
        },
        breakpoints: {
            1080: {
                slidesPerView: 3,
            },
            780: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            300: {
                slidesPerView: 1,
                spaceBetween: 20,
                autoHeight: true,
            },
        },
    });

});